import React, {useState, useCallback, Fragment} from 'react'
import {
    Button,
    LegacyStack,
    Icon,
    Modal,
    TextContainer,
    LegacyCard,
    Text,
    InlineGrid,
    Box,
    RadioButton,
    BlockStack,
} from "@shopify/polaris"
import formatCurrency from '../../../../helpers/formatCurrency'
import { DeleteIcon, ChevronUpIcon, ChevronDownIcon } from "@shopify/polaris-icons";
import CardDivider from '../../../../components/cardDivider';
import styled from 'styled-components'
import update from 'immutability-helper'
const ABTestingWrapper = styled.div`
    margin: 20px 0;
    .ab-analytics {
        margin: 0 10px;
    }
    .ab-tabs {
        margin-top: 10px;
        border-top: 1px solid #DFE3E8;
        border-bottom: 1px solid #DFE3E8;
        .version-item {
            text-align: center;
            padding: 15px 0;
            cursor: pointer;
            svg {
                fill: #D72C0D;
                width: 80%
                height: 80%
            }
        }
        .active {
            background: #F2F7FE;
            border-bottom: 2px solid #2C6ECB;

        }
        .b-version {
            background: #F1F8F5;
            border-bottom: 2px solid #008060;
        }
    }
`
const ButtonWrapper = styled.div`
    margin: 20px 0 0 0;
`
function ABTesting(props) {
    const {  versionAnalytics, versionData, setVersionData, checkoutOfferType, version, setVersion, state, setState, locale, currency } = props
    const [ ABTestingEdit, setABTestingEdit ] = useState(true);
    const [activeA, setActiveA] = useState(false);
    const [activeB, setActiveB] = useState(false);
    const handleAChange = () => setActiveA(!activeA)
    
    const handleBChange = () => setActiveB(!activeB)
    const activatorA = <div onClick={handleAChange}><Button icon={DeleteIcon} tone="critical"></Button></div>
    const activatorB = <div onClick={handleBChange}><Button icon={DeleteIcon} tone="critical"></Button></div>
    const handleVersionChange = (_checked, newValue) => {
            if(newValue === 'A'){
                if("B"===version){
                    const newStateData = {...state, ...versionData, versionB:{
                        layout: state.layout, banner: state.banner, message: state.message,
                        subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice,
                        product: state.product, variantsTriggers: state.variantsTriggers, ctaStyles: state.ctaStyles,
                        textStyles: state.textStyles, swapPrice: state.swapPrice, generalLayout: state.generalLayout,
                        addToCartAction: state.addToCartAction, border: state.border, showQuantitySelector: state.showQuantitySelector,
                    }}
                    setState(newStateData)
                }
            }else{
                if("A"===version){
                    setVersionData({
                        layout: state.layout, banner: state.banner, message: state.message,
                        subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice,
                        product: state.product, variantsTriggers: state.variantsTriggers, ctaStyles: state.ctaStyles,
                        textStyles: state.textStyles, swapPrice: state.swapPrice, generalLayout: state.generalLayout,
                        addToCartAction: state.addToCartAction, border: state.border, showQuantitySelector: state.showQuantitySelector,
                    })
                    const newStateData = {...state, ...{
                        layout: state.versionB.layout, banner: state.versionB.banner, message: state.versionB.message,
                        subtitle: state.versionB.subtitle, cta: state.versionB.cta, compareAtPrice: state.versionB.compareAtPrice,
                        product: state.versionB.product, variantsTriggers: state.versionB.variantsTriggers, ctaStyles: state.versionB.ctaStyles,
                        textStyles: state.versionB.textStyles, swapPrice: state.versionB.swapPrice, generalLayout: state.versionB.generalLayout,
                        addToCartAction: state.versionB.addToCartAction, border: state.versionB.border, showQuantitySelector: state.versionB.showQuantitySelector,

                    }}
                    setState(newStateData)
                }

            }
            setVersion(newValue)
            
        }
    const enableABTesting = () => {
     
        setState(update(state, { versionB: { $set: {
            layout: state.layout, banner: state.banner, message: state.message,
            subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice,
            product: state.product, variantsTriggers: state.variantsTriggers, ctaStyles: state.ctaStyles,
            textStyles: state.textStyles, swapPrice: state.swapPrice, generalLayout: state.generalLayout,
            addToCartAction: state.addToCartAction, border: state.border, showQuantitySelector: state.showQuantitySelector,
        } }}))
        setVersion("A")
    }
    const removeVersionA = () => {
        if("A" === version){
            setState({...state, ...state.versionB, versionB: null})
        }
        if("B" === version){
            setState({...state, versionB: null})
        }
        setVersion(null)
    }
    const removeVersionB = () => {
        if("A" === version){
            setState({...state, versionB: null})
        }
        if("B" === version){
            setState({...state, ...versionData, versionB: null})
        }
        setVersion(null)
    }
    return (
      (state.versionB ?
      <LegacyCard sectioned>
              <div>
                <LegacyStack alignment="center">
                    <LegacyStack.Item fill>
                    <Text as="h2" variant="headingMd">A/B Version</Text>
                    <Text tone="subdued">
                        Impressions, Conversion Rate, Revenue
                    </Text>
                    </LegacyStack.Item>                      
                </LegacyStack>
              </div>
              <CardDivider/>
              <Box paddingBlock="400">
              <InlineGrid gap="400" columns={3}>
                  <LegacyCard sectioned>
                      <Box paddingBlockEnd="100" borderBlockStartWidth="0" borderInlineStartWidth="0" borderInlineEndWidth="0" borderBlockEndWidth="025" borderStyle="dashed" width="auto">
                          <Text variant="headingSm" as="h6">Impressions</Text>
                      </Box>
                      <Box paddingBlockStart="400">
                          <Text as='p'>{versionAnalytics&&versionAnalytics[version]?versionAnalytics[version].totalImpressionCount:0}</Text>
                      </Box>
                  </LegacyCard>
                  <LegacyCard sectioned>
                      <Box paddingBlockEnd="100" borderBlockStartWidth="0" borderInlineStartWidth="0" borderInlineEndWidth="0" borderBlockEndWidth="025" borderStyle="dashed" width="auto">
                          <Text variant="headingSm" as="h6">Conversion rate</Text>
                      </Box>
                      <Box paddingBlockStart="400">
                          <Text as='p'>{versionAnalytics&&versionAnalytics[version]?versionAnalytics[version].conversion:'-%'}</Text>
                      </Box>
                  </LegacyCard>
                  <LegacyCard sectioned>
                      <Box paddingBlockEnd="100" borderBlockStartWidth="0" borderInlineStartWidth="0" borderInlineEndWidth="0" borderBlockEndWidth="025" borderStyle="dashed" width="auto">
                          <Text variant="headingSm" as="h6">Revenue</Text>
                      </Box>
                      <Box paddingBlockStart="400">
                          <Text as='p'>{versionAnalytics&&versionAnalytics[version]&&versionAnalytics[version].totalUpsoldRevenue?formatCurrency(versionAnalytics[version].totalUpsoldRevenue,locale,currency):formatCurrency(0, locale, currency)}</Text>
                      </Box>
                  </LegacyCard>
              </InlineGrid>
              <Box paddingBlockStart="400">
                  <Text as="p">Select Version</Text>
                  <LegacyStack>
                      <LegacyStack.Item fill>
                      <Box>
                          <BlockStack gap="300">
                              <RadioButton
                                  label="Version A (50%)"
                                  checked={version === 'A'}
                                  id="A"
                                  name="abversion"
                                  onChange={handleVersionChange}
                              />
                              <RadioButton
                                  label="Version B (50%)"
                                  id="B"
                                  name="abversion"
                                  checked={version === 'B'}
                                  onChange={handleVersionChange}
                              />
                          </BlockStack>
                      </Box>
                      </LegacyStack.Item>
                      <LegacyStack.Item>
                      <Box>
                      <BlockStack gap="300">
                      <Modal
                              activator={activatorA}
                              open={activeA}
                              onClose={handleAChange}
                              title="Remove version"
                              primaryAction={{
                              content: 'Remove version',
                              onAction: removeVersionA,
                              destructive: true,
                              }}
                              secondaryActions={[
                              {
                                  content: 'Cancel',
                                  onAction: handleAChange,
                              },
                              ]}
                          >
                              <Modal.Section>
                              <TextContainer>
                                  <p>
                                  This will remove all analytics associated. This cannot be undone.
                                  </p>
                              </TextContainer>
                              </Modal.Section>
                          </Modal>
                          <Modal
                                  activator={activatorB}
                                  open={activeB}
                                  onClose={handleBChange}
                                  title="Remove version"
                                  primaryAction={{
                                  content: 'Remove version',
                                  onAction: removeVersionB,
                                  destructive: true,
                                  }}
                                  secondaryActions={[
                                  {
                                      content: 'Cancel',
                                      onAction: handleBChange,
                                  },
                                  ]}
                              >
                                  <Modal.Section>
                                  <TextContainer>
                                      <p>
                                      This will remove all analytics associated. This cannot be undone.
                                      </p>
                                  </TextContainer>
                                  </Modal.Section>
                              </Modal>
                          </BlockStack>
                      </Box>
                      </LegacyStack.Item>
                  </LegacyStack>
              </Box>
              </Box>
          </LegacyCard>
          :
          <LegacyCard sectioned>
              <div
              onClick={() => setABTestingEdit(false)}
              >
              <LegacyStack alignment="center">
                  <LegacyStack.Item fill>
                  <Text as="h2" variant="headingMd">A/B Version</Text>
                  <Text tone="subdued">
                      Impressions, Conversion Rate, Revenue
                  </Text>
                  </LegacyStack.Item>
              </LegacyStack>
              </div>
              <CardDivider/>
              <ButtonWrapper>
                  <Button variant="primary" onClick={()=>enableABTesting()}>Add an A/B version</Button>
              </ButtonWrapper>
          </LegacyCard>
      )   
    )
}
export default ABTesting;